import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as indexs3XnOKm92nMeta } from "/app/pages/admin/blog/index.vue?macro=true";
import { default as index63YNrETJ7ZMeta } from "/app/pages/admin/catalog-references/index.vue?macro=true";
import { default as change_45logiouSlPma9qMeta } from "/app/pages/admin/catalog/change-log.vue?macro=true";
import { default as indexw7gHA70C9pMeta } from "/app/pages/admin/catalog/index.vue?macro=true";
import { default as mebDO5Pay1B9Meta } from "/app/pages/admin/catalog/me.vue?macro=true";
import { default as indexOGoyqUcd4PMeta } from "/app/pages/admin/faq/index.vue?macro=true";
import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as loginjuBiVElJesMeta } from "/app/pages/admin/login.vue?macro=true";
import { default as indexRgGfAkY0MMMeta } from "/app/pages/admin/pages/index.vue?macro=true";
import { default as indexXjiWyg4abpMeta } from "/app/pages/admin/references/index.vue?macro=true";
import { default as index6CKRkvYDj5Meta } from "/app/pages/admin/tags/index.vue?macro=true";
import { default as indexKzNkAUINzQMeta } from "/app/pages/admin/texts/index.vue?macro=true";
import { default as unauthorizedKT3TuNtjIEMeta } from "/app/pages/admin/unauthorized.vue?macro=true";
import { default as indexYm9dzWmM2AMeta } from "/app/pages/admin/users/index.vue?macro=true";
import { default as verify_45emailSW461DJyQKMeta } from "/app/pages/admin/verify-email.vue?macro=true";
import { default as indexvSQmcOZmizMeta } from "/app/pages/admin/vocabulary/index.vue?macro=true";
import { default as indexFTwI8RiIAkMeta } from "/app/pages/admin/zdarma/index.vue?macro=true";
import { default as _91_46_46_46slug_93jK3Pyj8dSOMeta } from "/app/pages/blog/[...slug].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_939Gp5g4x6jnMeta } from "/app/pages/katalog/[...slug].vue?macro=true";
import { default as kontaktzJWjXi4cLvMeta } from "/app/pages/kontakt.vue?macro=true";
import { default as networkingph2x8gsdyAMeta } from "/app/pages/networking.vue?macro=true";
import { default as _91_46_46_46slug_93xuf2VKbD91Meta } from "/app/pages/poradenstvi/[...slug].vue?macro=true";
import { default as registrace_45asistentekgpwtcxg0QsMeta } from "/app/pages/registrace-asistentek.vue?macro=true";
import { default as _91_46_46_46slug_936kE8eh5anMMeta } from "/app/pages/slovnik/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93tu0picYvsXMeta } from "/app/pages/zdarma/[...slug].vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93ILszah2VNpMeta?.name ?? "slug",
    path: _91_46_46_46slug_93ILszah2VNpMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93ILszah2VNpMeta || {},
    alias: _91_46_46_46slug_93ILszah2VNpMeta?.alias || [],
    redirect: _91_46_46_46slug_93ILszah2VNpMeta?.redirect,
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexs3XnOKm92nMeta?.name ?? "admin-blog",
    path: indexs3XnOKm92nMeta?.path ?? "/admin/blog",
    meta: indexs3XnOKm92nMeta || {},
    alias: indexs3XnOKm92nMeta?.alias || [],
    redirect: indexs3XnOKm92nMeta?.redirect,
    component: () => import("/app/pages/admin/blog/index.vue").then(m => m.default || m)
  },
  {
    name: index63YNrETJ7ZMeta?.name ?? "admin-catalog-references",
    path: index63YNrETJ7ZMeta?.path ?? "/admin/catalog-references",
    meta: index63YNrETJ7ZMeta || {},
    alias: index63YNrETJ7ZMeta?.alias || [],
    redirect: index63YNrETJ7ZMeta?.redirect,
    component: () => import("/app/pages/admin/catalog-references/index.vue").then(m => m.default || m)
  },
  {
    name: change_45logiouSlPma9qMeta?.name ?? "admin-catalog-change-log",
    path: change_45logiouSlPma9qMeta?.path ?? "/admin/catalog/change-log",
    meta: change_45logiouSlPma9qMeta || {},
    alias: change_45logiouSlPma9qMeta?.alias || [],
    redirect: change_45logiouSlPma9qMeta?.redirect,
    component: () => import("/app/pages/admin/catalog/change-log.vue").then(m => m.default || m)
  },
  {
    name: indexw7gHA70C9pMeta?.name ?? "admin-catalog",
    path: indexw7gHA70C9pMeta?.path ?? "/admin/catalog",
    meta: indexw7gHA70C9pMeta || {},
    alias: indexw7gHA70C9pMeta?.alias || [],
    redirect: indexw7gHA70C9pMeta?.redirect,
    component: () => import("/app/pages/admin/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: mebDO5Pay1B9Meta?.name ?? "admin-catalog-me",
    path: mebDO5Pay1B9Meta?.path ?? "/admin/catalog/me",
    meta: mebDO5Pay1B9Meta || {},
    alias: mebDO5Pay1B9Meta?.alias || [],
    redirect: mebDO5Pay1B9Meta?.redirect,
    component: () => import("/app/pages/admin/catalog/me.vue").then(m => m.default || m)
  },
  {
    name: indexOGoyqUcd4PMeta?.name ?? "admin-faq",
    path: indexOGoyqUcd4PMeta?.path ?? "/admin/faq",
    meta: indexOGoyqUcd4PMeta || {},
    alias: indexOGoyqUcd4PMeta?.alias || [],
    redirect: indexOGoyqUcd4PMeta?.redirect,
    component: () => import("/app/pages/admin/faq/index.vue").then(m => m.default || m)
  },
  {
    name: indexi8nCATZISJMeta?.name ?? "admin",
    path: indexi8nCATZISJMeta?.path ?? "/admin",
    meta: indexi8nCATZISJMeta || {},
    alias: indexi8nCATZISJMeta?.alias || [],
    redirect: indexi8nCATZISJMeta?.redirect,
    component: () => import("/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: loginjuBiVElJesMeta?.name ?? "admin-login",
    path: loginjuBiVElJesMeta?.path ?? "/admin/login",
    meta: loginjuBiVElJesMeta || {},
    alias: loginjuBiVElJesMeta?.alias || [],
    redirect: loginjuBiVElJesMeta?.redirect,
    component: () => import("/app/pages/admin/login.vue").then(m => m.default || m)
  },
  {
    name: indexRgGfAkY0MMMeta?.name ?? "admin-pages",
    path: indexRgGfAkY0MMMeta?.path ?? "/admin/pages",
    meta: indexRgGfAkY0MMMeta || {},
    alias: indexRgGfAkY0MMMeta?.alias || [],
    redirect: indexRgGfAkY0MMMeta?.redirect,
    component: () => import("/app/pages/admin/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexXjiWyg4abpMeta?.name ?? "admin-references",
    path: indexXjiWyg4abpMeta?.path ?? "/admin/references",
    meta: indexXjiWyg4abpMeta || {},
    alias: indexXjiWyg4abpMeta?.alias || [],
    redirect: indexXjiWyg4abpMeta?.redirect,
    component: () => import("/app/pages/admin/references/index.vue").then(m => m.default || m)
  },
  {
    name: index6CKRkvYDj5Meta?.name ?? "admin-tags",
    path: index6CKRkvYDj5Meta?.path ?? "/admin/tags",
    meta: index6CKRkvYDj5Meta || {},
    alias: index6CKRkvYDj5Meta?.alias || [],
    redirect: index6CKRkvYDj5Meta?.redirect,
    component: () => import("/app/pages/admin/tags/index.vue").then(m => m.default || m)
  },
  {
    name: indexKzNkAUINzQMeta?.name ?? "admin-texts",
    path: indexKzNkAUINzQMeta?.path ?? "/admin/texts",
    meta: indexKzNkAUINzQMeta || {},
    alias: indexKzNkAUINzQMeta?.alias || [],
    redirect: indexKzNkAUINzQMeta?.redirect,
    component: () => import("/app/pages/admin/texts/index.vue").then(m => m.default || m)
  },
  {
    name: unauthorizedKT3TuNtjIEMeta?.name ?? "admin-unauthorized",
    path: unauthorizedKT3TuNtjIEMeta?.path ?? "/admin/unauthorized",
    meta: unauthorizedKT3TuNtjIEMeta || {},
    alias: unauthorizedKT3TuNtjIEMeta?.alias || [],
    redirect: unauthorizedKT3TuNtjIEMeta?.redirect,
    component: () => import("/app/pages/admin/unauthorized.vue").then(m => m.default || m)
  },
  {
    name: indexYm9dzWmM2AMeta?.name ?? "admin-users",
    path: indexYm9dzWmM2AMeta?.path ?? "/admin/users",
    meta: indexYm9dzWmM2AMeta || {},
    alias: indexYm9dzWmM2AMeta?.alias || [],
    redirect: indexYm9dzWmM2AMeta?.redirect,
    component: () => import("/app/pages/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: verify_45emailSW461DJyQKMeta?.name ?? "admin-verify-email",
    path: verify_45emailSW461DJyQKMeta?.path ?? "/admin/verify-email",
    meta: verify_45emailSW461DJyQKMeta || {},
    alias: verify_45emailSW461DJyQKMeta?.alias || [],
    redirect: verify_45emailSW461DJyQKMeta?.redirect,
    component: () => import("/app/pages/admin/verify-email.vue").then(m => m.default || m)
  },
  {
    name: indexvSQmcOZmizMeta?.name ?? "admin-vocabulary",
    path: indexvSQmcOZmizMeta?.path ?? "/admin/vocabulary",
    meta: indexvSQmcOZmizMeta || {},
    alias: indexvSQmcOZmizMeta?.alias || [],
    redirect: indexvSQmcOZmizMeta?.redirect,
    component: () => import("/app/pages/admin/vocabulary/index.vue").then(m => m.default || m)
  },
  {
    name: indexFTwI8RiIAkMeta?.name ?? "admin-zdarma",
    path: indexFTwI8RiIAkMeta?.path ?? "/admin/zdarma",
    meta: indexFTwI8RiIAkMeta || {},
    alias: indexFTwI8RiIAkMeta?.alias || [],
    redirect: indexFTwI8RiIAkMeta?.redirect,
    component: () => import("/app/pages/admin/zdarma/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93jK3Pyj8dSOMeta?.name ?? "blog-slug",
    path: _91_46_46_46slug_93jK3Pyj8dSOMeta?.path ?? "/blog/:slug(.*)*",
    meta: _91_46_46_46slug_93jK3Pyj8dSOMeta || {},
    alias: _91_46_46_46slug_93jK3Pyj8dSOMeta?.alias || [],
    redirect: _91_46_46_46slug_93jK3Pyj8dSOMeta?.redirect,
    component: () => import("/app/pages/blog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_939Gp5g4x6jnMeta?.name ?? "katalog-slug",
    path: _91_46_46_46slug_939Gp5g4x6jnMeta?.path ?? "/katalog/:slug(.*)*",
    meta: _91_46_46_46slug_939Gp5g4x6jnMeta || {},
    alias: _91_46_46_46slug_939Gp5g4x6jnMeta?.alias || [],
    redirect: _91_46_46_46slug_939Gp5g4x6jnMeta?.redirect,
    component: () => import("/app/pages/katalog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: kontaktzJWjXi4cLvMeta?.name ?? "kontakt",
    path: kontaktzJWjXi4cLvMeta?.path ?? "/kontakt",
    meta: kontaktzJWjXi4cLvMeta || {},
    alias: kontaktzJWjXi4cLvMeta?.alias || [],
    redirect: kontaktzJWjXi4cLvMeta?.redirect,
    component: () => import("/app/pages/kontakt.vue").then(m => m.default || m)
  },
  {
    name: networkingph2x8gsdyAMeta?.name ?? "networking",
    path: networkingph2x8gsdyAMeta?.path ?? "/networking",
    meta: networkingph2x8gsdyAMeta || {},
    alias: networkingph2x8gsdyAMeta?.alias || [],
    redirect: networkingph2x8gsdyAMeta?.redirect,
    component: () => import("/app/pages/networking.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93xuf2VKbD91Meta?.name ?? "poradenstvi-slug",
    path: _91_46_46_46slug_93xuf2VKbD91Meta?.path ?? "/poradenstvi/:slug(.*)*",
    meta: _91_46_46_46slug_93xuf2VKbD91Meta || {},
    alias: _91_46_46_46slug_93xuf2VKbD91Meta?.alias || [],
    redirect: _91_46_46_46slug_93xuf2VKbD91Meta?.redirect,
    component: () => import("/app/pages/poradenstvi/[...slug].vue").then(m => m.default || m)
  },
  {
    name: registrace_45asistentekgpwtcxg0QsMeta?.name ?? "registrace-asistentek",
    path: registrace_45asistentekgpwtcxg0QsMeta?.path ?? "/registrace-asistentek",
    meta: registrace_45asistentekgpwtcxg0QsMeta || {},
    alias: registrace_45asistentekgpwtcxg0QsMeta?.alias || [],
    redirect: registrace_45asistentekgpwtcxg0QsMeta?.redirect,
    component: () => import("/app/pages/registrace-asistentek.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_936kE8eh5anMMeta?.name ?? "slovnik-slug",
    path: _91_46_46_46slug_936kE8eh5anMMeta?.path ?? "/slovnik/:slug(.*)*",
    meta: _91_46_46_46slug_936kE8eh5anMMeta || {},
    alias: _91_46_46_46slug_936kE8eh5anMMeta?.alias || [],
    redirect: _91_46_46_46slug_936kE8eh5anMMeta?.redirect,
    component: () => import("/app/pages/slovnik/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93tu0picYvsXMeta?.name ?? "zdarma-slug",
    path: _91_46_46_46slug_93tu0picYvsXMeta?.path ?? "/zdarma/:slug(.*)*",
    meta: _91_46_46_46slug_93tu0picYvsXMeta || {},
    alias: _91_46_46_46slug_93tu0picYvsXMeta?.alias || [],
    redirect: _91_46_46_46slug_93tu0picYvsXMeta?.redirect,
    component: () => import("/app/pages/zdarma/[...slug].vue").then(m => m.default || m)
  }
]